<template>
  <div class="bg-white" v-if="sectionData">
    <!-- Background color split screen for large screens -->
    <div
      class="hidden lg:block fixed top-0 left-0 w-1/2 h-full bg-white"
      aria-hidden="true"
    />
    <div
      class="hidden lg:block fixed top-0 right-0 w-1/2 h-full bg-gray-50"
      aria-hidden="true"
    />

    <header
      class="
        relative
        bg-white
        border-b border-gray-200
        text-sm
        font-medium
        text-gray-700
      "
    >
      <div class="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div class="relative flex">
          <nav aria-label="Progress" class="hidden sm:block">
            <ol role="list" class="flex space-x-4">
              <li
                v-for="(step, stepIdx) in steps"
                :key="step.name"
                class="flex items-center"
              >
                <a
                  v-if="step.status === 'current'"
                  :href="step.href"
                  aria-current="page"
                  class="text-indigo-600"
                  >{{ step.name }}</a
                >
                <a v-else :href="step.href">{{ step.name }}</a>
                <ChevronRightIcon
                  v-if="stepIdx !== steps.length - 1"
                  class="w-5 h-5 text-gray-300 ml-4"
                  aria-hidden="true"
                />
              </li>
            </ol>
          </nav>
          <p class="sm:hidden">Step 2 of 4</p>
        </div>
      </div>
    </header>

    <main class="bg-white relative lg:min-h-screen">
      <div
        class="
          h-80
          overflow-hidden
          lg:absolute lg:w-1/2 lg:h-full lg:pr-4
          xl:pr-12
        "
      >
        <img
          src="https://tailwindui.com/img/ecommerce-images/confirmation-page-06-hero.jpg"
          alt="TODO"
          class="h-full w-full object-center object-cover"
        />
      </div>

      <div>
        <div
          class="
            max-w-2xl
            mx-auto
            py-16
            px-4
            sm:px-6 sm:py-24
            lg:max-w-7xl lg:px-8 lg:py-32 lg:grid lg:grid-cols-2 lg:gap-x-8
            xl:gap-x-24
          "
        >
          <div class="lg:col-start-2">
            <h1 class="text-sm font-medium text-indigo-600">
              {{ sectionData[0].topText }}
            </h1>
            <p
              class="
                mt-2
                text-4xl
                font-extrabold
                tracking-tight
                text-gray-900
                sm:text-5xl
              "
            >
              {{ sectionData[0].middleText }}
            </p>
            <p class="mt-2 text-base text-gray-500">
              {{ sectionData[0].bottomText }}
            </p>

            <ul
              role="list"
              class="
                max-auto
                mt-6
                text-sm
                font-medium
                text-gray-500
                border-t border-gray-200
                divide-y divide-gray-200
              "
            >
              <li
                v-for="product in products"
                :key="product.id"
                class="flex py-6 space-x-6"
              >
                <img
                  :src="product.imageSrc"
                  :alt="product.imageAlt"
                  class="
                    flex-none
                    w-24
                    h-24
                    bg-gray-100
                    rounded-md
                    object-center object-cover
                  "
                />
                <div class="flex-auto space-y-1 my-auto">
                  <h3 class="text-gray-900">
                    <a :href="product.href">{{ product.name }}</a>
                  </h3>
                </div>
                <p class="flex-none font-medium text-gray-900 my-auto">
                  {{ product.price }}
                </p>
              </li>
            </ul>

            <dl
              class="
                text-sm
                font-medium
                text-gray-500
                space-y-6
                border-t border-gray-200
                pt-6
              "
            >
              <div class="flex justify-between">
                <dt>Subtotal</dt>
                <dd class="text-gray-900">$72.00</dd>
              </div>

              <div class="flex justify-between">
                <dt>Shipping</dt>
                <dd class="text-gray-900">$8.00</dd>
              </div>

              <div class="flex justify-between">
                <dt>Taxes</dt>
                <dd class="text-gray-900">$6.40</dd>
              </div>

              <div
                class="
                  flex
                  items-center
                  justify-between
                  border-t border-gray-200
                  text-gray-900
                  pt-6
                "
              >
                <dt class="text-base">Total</dt>
                <dd class="text-base">$86.40</dd>
              </div>
            </dl>

            <dl class="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
              <div>
                <dt class="font-medium text-gray-900">Billing Address</dt>
                <dd class="mt-2">
                  <address class="not-italic">
                    <span class="block">Kristin Watson</span>
                    <span class="block">7363 Cynthia Pass</span>
                    <span class="block">Toronto, ON N3Y 4H8</span>
                  </address>
                </dd>
              </div>
              <div>
                <dt class="font-medium text-gray-900">Payment Information</dt>
                <dd class="mt-2 space-y-2 sm:flex sm:space-y-0 sm:space-x-4">
                  <div class="flex-none">
                    <svg
                      aria-hidden="true"
                      width="36"
                      height="24"
                      viewBox="0 0 36 24"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-auto"
                    >
                      <rect width="36" height="24" rx="4" fill="#224DBA" />
                      <path
                        d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                        fill="#fff"
                      />
                    </svg>
                    <p class="sr-only">Visa</p>
                  </div>
                  <div class="flex-auto">
                    <p class="text-gray-900 text-sm">Ending with 4242</p>
                    <p class="text-sm">Expires 12 / 21</p>
                  </div>
                </dd>
              </div>
            </dl>

            <div class="mt-16 border-t border-gray-200 py-6">
              <router-link
                to="/Dashboard"
                class="
                  w-full
                  bg-indigo-600
                  border border-transparent
                  rounded-md
                  shadow-sm
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-white
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-offset-gray-50
                  focus:ring-indigo-500
                  sm:order-last
                  block
                  text-center
                "
                >Build my profile &nbsp;<span aria-hidden="true">
                  &rarr;</span
                ></router-link>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/vue/solid";
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { OcContentService } from "../services/ocContentService";
import { CookieService } from '../services/cookieService';

const steps = [
  { name: "Checkout", href: "/checkout", status: "complete" },
  { name: "Thank You", href: "/thankyou", status: "current" },
];

const products = [
  {
    id: 1,
    name: "Annual Subscription",
    href: "#",
    price: "$299.99",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/checkout-page-04-product-01.jpg",
    imageAlt:
      "Moss green canvas compact backpack with double top zipper, zipper front pouch, and matching carry handle and backpack straps.",
  },
  // More products...
];

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
    ChevronRightIcon,
    ChevronUpIcon,
  },
  setup() {
    return {
      steps,
      products,
    };
  },
  data() {
    return {
      sectionData: null,
    };
  },
  created() {
    //need to do this to check whether the nav bar needs to be updated
    if (!CookieService.getLoginCookie()) {
      this.emitter.emit("logged-in", false);
    }
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Thank You",
        "KrickersSectionContent"
      );
    },
  },
};
</script>
